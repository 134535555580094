import {
  ADD_HTMLCONTENT,
  GET_HTMLCONTENT_LOADING,
  GET_HTMLCONTENT_SUCCESS,
  GET_HTMLCONTENT_ERROR,
  GET_HTMLCONTENT_ROWS_PER_PAGE,
  GET_HTMLCONTENT_PAGE_NUMBER,
  GET_MORE_HTMLCONTENT_LOADING,
} from "../constants/ActionTypes";

const INITIAL_STATE = {
  htmlcontentList: [],
  singleHtmlcontentId: "",
  singleHtmlcontentMap: {},
  loading: false,
  singleHtmlcontentLoading: false,
  error: "",
  rowsPerPage: 10,
  page: 0,
  lastDocument: null,
  moreHtmlcontentLoading: false,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    
    case ADD_HTMLCONTENT:
      return {
        ...state,
        htmlcontentList: [...state.htmlcontentList, action.payload]
      };

    case GET_HTMLCONTENT_LOADING:
      return {
        ...state,
        loading: action.payload
      };

    case GET_MORE_HTMLCONTENT_LOADING:
      return {
        ...state,
        moreHtmlcontentLoading: action.payload
      };

    case GET_HTMLCONTENT_SUCCESS:
      return {
        ...state,
        loading: false,
        moreHtmlcontentLoading: false,
        htmlcontenList: [...state.htmlcontentList,...action.payload.htmlcontentList],
        lastDocument: action.payload.lastDocument,
      };

    case GET_HTMLCONTENT_ERROR:
      return {
        ...state,
        loading: false,
        moreHtmlcontentLoading: false,
        error: action.payload
      };

    case GET_HTMLCONTENT_ROWS_PER_PAGE:
      return {
        ...state,
        rowsPerPage: action.payload
      };

    case GET_HTMLCONTENT_PAGE_NUMBER:
      return {
        ...state,
        page: action.payload
      };




    
    default:
      return state;
  }
};
