import {
  ADD_HTMLCONTENT,
  GET_MORE_HTMLCONTENT,
  GET_MORE_HTMLCONTENT_LOADING,
  GET_HTMLCONTENT,
  GET_HTMLCONTENT_ERROR,
  GET_HTMLCONTENT_SUCCESS,
  GET_HTMLCONTENT_LOADING,
  GET_HTMLCONTENT_ROWS_PER_PAGE,
  GET_HTMLCONTENT_PAGE_NUMBER,

} from "../constants/ActionTypes";

export const addHtmlcontent = htmlcontentObject => {
  return {
    type: ADD_HTMLCONTENT,
    payload: htmlcontentObject,
  }
}


export const getHtmlcontent = () => {
  return {
    type: GET_HTMLCONTENT
  };
};

export const getMoreHtmlcontent = (lastDocument) => {
  return {
    type: GET_MORE_HTMLCONTENT,
    payload: {
      lastDocument: lastDocument,
    }
  };
};

export const getHtmlcontentLoading = value => {
  return {
    type: GET_HTMLCONTENT_LOADING,
    payload: value
  };
};

export const getMoreHtmlcontentLoading = value => {
  return {
    type: GET_MORE_HTMLCONTENT_LOADING,
    payload: value
  };
};

export const getHtmlcontentError = error => {
  return {
    type: GET_HTMLCONTENT_ERROR,
    payload: error
  };
};

export const getHtmlcontentSuccess = (htmlcontentList, lastDocument) => {
  return {
    type: GET_HTMLCONTENT_SUCCESS,
    payload: {
      htmlcontentList,
      lastDocument
    }
  };
};

export const setRowsPerPage = value => {
  return {
    type: GET_HTMLCONTENT_ROWS_PER_PAGE,
    payload: value
  };
};

