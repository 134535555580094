import {
  GET_BLOGS,
  GET_BLOGS_ERROR,
  GET_BLOGS_SUCCESS,
  GET_BLOGS_LOADING,
  GET_BLOGS_ROWS_PER_PAGE,
  GET_BLOGS_PAGE_NUMBER,
  UPDATE_BLOG,
  DELETE_BLOG,
  ADD_BLOG,
  GET_MORE_BLOGS,
  GET_MORE_BLOGS_LOADING,
} from "constants/ActionTypes";

export const getBlogs = () => {
  return {
    type: GET_BLOGS
  };
};

export const getMoreBlogs = (lastDocument) => {
  return {
    type: GET_MORE_BLOGS,
    payload: {
      lastDocument: lastDocument,
    }
  };
};

export const getBlogsLoading = value => {
  return {
    type: GET_BLOGS_LOADING,
    payload: value
  };
};

export const getMoreBlogsLoading = value => {
  return {
    type: GET_MORE_BLOGS_LOADING,
    payload: value
  };
};

export const getBlogsError = error => {
  return {
    type: GET_BLOGS_ERROR,
    payload: error
  };
};

export const getBlogsSuccess = (blogsList, lastDocument) => {
  return {
    type: GET_BLOGS_SUCCESS,
    payload: {
      blogsList,
      lastDocument
    }
  };
};

export const setRowsPerPage = value => {
  return {
    type: GET_BLOGS_ROWS_PER_PAGE,
    payload: value
  };
};

export const setPageNumber = value => {
  return {
    type: GET_BLOGS_PAGE_NUMBER,
    payload: value
  };
};

export const updateBlog = blogObject => {
  return {
    type: UPDATE_BLOG,
    payload: blogObject
  };
};

export const addBlog = blogObject => {
  return {
    type: ADD_BLOG,
    payload: blogObject
  };
};

export const deleteBlog = blogObject => {
  return {
    type: DELETE_BLOG,
    payload: blogObject
  };
};

// export const getMoreBLOGs = () => {
//   return {
//     type: GET_MORE_BLOGS
//   };
// };

// export const getSingleBLOG = () => {
//   return {
//     type: GET_SINGLE_BLOG
//   };
// };
