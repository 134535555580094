import { all, call, fork, put, takeEvery, takeLatest } from "redux-saga/effects";
import { GET_FAQ, GET_MORE_FAQ } from "../constants/ActionTypes";
import { getFaqError, getFaqSuccess } from "../actions/Faq";
import FirestoreService from "../services/firestoreService";

function* getFaqHelper() {
  try {
    const result = yield call(FirestoreService.getFaq);
    if (result.data !== undefined) {
      // It means there is  data to display
      yield put(getFaqSuccess(result.data, result.lastDocument));
    } else if (result.noData !== undefined) {
      // It means there is no data to display
      // console.log(result.noData);
      yield put(getFaqError(result.noData));
    } else {
      // It means there is error
      // console.log(result.error);
      yield put(getFaqError(result.error));
    }
  } catch (e) {
    console.log(e);
  }
}

function* getMoreFaqHelper({payload}) {
  try {
    const result = yield call(FirestoreService.getMoreFaq, payload.lastDocument);
    if (result.data !== undefined) {
      // It means there is  data to display
      yield put(getFaqSuccess(result.data, result.lastDocument));
    } else if (result.noData !== undefined) {
      // It means there is no data to display
      // console.log(result.noData);
      yield put(getFaqError(result.noData));
    } else {
      // It means there is error
      // console.log(result.error);
      yield put(getFaqError(result.error));
    }
  } catch (e) {
    console.log(e);
  }
}

export function* getFaqSaga() {
  yield takeEvery(GET_FAQ, getFaqHelper);
}

export function* getMoreFaqSaga() {
  yield takeLatest(GET_MORE_FAQ, getMoreFaqHelper);
}

export default function* rootSaga() {
  yield all([fork(getFaqSaga), fork(getMoreFaqSaga)]);
}
