import { combineReducers } from "redux";
import { connectRouter } from "connected-react-router";
import Settings from "./Settings";
import Auth from "./Auth";
import Users from "./Users";
import Doctors from "./Doctors";
import Hospitals from "./Hospitals";
import Blogs from "./Blogs";
import Notifications from "./Notifications";
import Appointments from "./Appointments";
import Appointmentdash from "./Appointmentdash";
import Companyinfo from "./Companyinfo";
import Payments from "./Payments";
import Payouts from "./Payouts";
import Illness from "./Illness";
import Htmlcontent from "./Htmlcontent";
import Faq from "./Faq";
import Admin from "./Admin";

export default (history) =>
  combineReducers({
    router: connectRouter(history),
    settings: Settings,
    auth: Auth,
    users: Users,
    doctors: Doctors,
    hospitals: Hospitals,
    blogs: Blogs,
    notifications: Notifications,
    appointments: Appointments,
    appointmentdash: Appointmentdash,
	companyinfo: Companyinfo,
    payments: Payments,
	payouts: Payouts,
    illness: Illness,
	htmlcontent: Htmlcontent,
	faq: Faq,
	admin: Admin,
  });
