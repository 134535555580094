import {
  GET_COMPANYINFO,
  GET_COMPANYINFO_ERROR,
  GET_COMPANYINFO_SUCCESS,
  GET_COMPANYINFO_LOADING,
} from "../constants/ActionTypes";

export const getCompanyInfo = () => {
  return {
    type: GET_COMPANYINFO,
  };
};

export const getCompanyInfoLoading = (value) => {
  return {
    type: GET_COMPANYINFO_LOADING,
    payload: value,
  };
};

export const getCompanyInfoError = (error) => {
  return {
    type: GET_COMPANYINFO_ERROR,
    payload: error,
  };
};

export const getCompanyInfoSuccess = (companyInfoList, lastDocument) => {
  return {
    type: GET_COMPANYINFO_SUCCESS,
    payload: {
      companyInfoList,
      lastDocument,
    },
  };
};