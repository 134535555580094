import { all, call, fork, put, takeEvery, takeLatest } from "redux-saga/effects";
import { GET_APPOINTMENTS,GET_MORE_APPOINTMENTS } from "../constants/ActionTypes";
import { getAppointmentsError, getAppointmentsSuccess } from "actions";
import FirestoreService from "services/firestoreService";

function* getAppointmentsHelper() {
  try {
    const result = yield call(FirestoreService.getAppointments);
    if (result.data !== undefined) {
      // It means there is  data to display
      yield put(getAppointmentsSuccess(result.data, result.lastDocument));
    } else if (result.noData !== undefined) {
      // It means there is no data to display
      // console.log(result.noData);
      yield put(getAppointmentsError(result.noData));
    } else {
      // It means there is error
      // console.log(result.error);
      yield put(getAppointmentsError(result.error));
    }
  } catch (e) {
    console.log(e);
  }
}

function* getMoreAppointmentsHelper({payload}) {
  try {
    const result = yield call(FirestoreService.getMoreAppointments, payload.lastDocument);
    if (result.data !== undefined) {
      // It means there is  data to display
      yield put(getAppointmentsSuccess(result.data, result.lastDocument));
    } else if (result.noData !== undefined) {
      // It means there is no data to display
      // console.log(result.noData);
      yield put(getAppointmentsError(result.noData));
    } else {
      // It means there is error
      // console.log(result.error);
      yield put(getAppointmentsError(result.error));
    }
  } catch (e) {
    console.log(e);
  }
}

export function* getAppointmentsSaga() {
  yield takeEvery(GET_APPOINTMENTS, getAppointmentsHelper);
}

export function* getMoreAppointmentsSaga() {
  yield takeLatest(GET_MORE_APPOINTMENTS, getMoreAppointmentsHelper);
}

export default function* rootSaga() {
  yield all([fork(getAppointmentsSaga), fork(getMoreAppointmentsSaga)]);
}
