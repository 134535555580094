import React, {useState} from "react";
import {Link, withRouter} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Button from "@material-ui/core/Button";
import {COLLAPSED_DRAWER, FIXED_DRAWER} from "constants/ActionTypes";
import {switchLanguage, toggleCollapsedNav} from "actions/Setting";
import IntlMessages from "util/IntlMessages";
import Typography from "@material-ui/core/Typography";
import { userSignOut } from "actions/Auth";

const Index =(props)=> {

  const dispatch = useDispatch();
  const { drawerType, locale } = useSelector(({settings}) => settings);

  const onToggleCollapsedNav = (e) => {
    const val = !props.navCollapsed;
    dispatch(toggleCollapsedNav(val));
  };

    const drawerStyle = drawerType.includes(FIXED_DRAWER) ? "d-block d-xl-none" : drawerType.includes(COLLAPSED_DRAWER) ? "d-block" : "d-none";

    return (
      <AppBar className="app-main-header" color="primary">
        <Toolbar className="app-toolbar" disableGutters={false} color="primary">
          <IconButton
            className={`jr-menu-icon mr-3 ${drawerStyle}`}
            aria-label="Menu"
            onClick={onToggleCollapsedNav}
          >
            <span className="menu-icon" />
          </IconButton>

          <ul className="header-notifications list-inline ml-auto">
            <li className="list-inline-item mail-tour">
              <Button
                onClick={() => {
                  // dispatch logout action
                  dispatch(userSignOut());
                }}
                disableElevation={true}
                variant="contained"
                className="bg-white"
              >
			  <div className="myFont" >
			   Logout
			  </div>
                
              </Button>
            </li>
          </ul>

          {/* <div className="ellipse-shape" /> */}
        </Toolbar>
      </AppBar>
    );
  };


export default withRouter(Index);
