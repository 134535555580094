import React from "react";
import { Route, Switch, withRouter } from "react-router-dom";
import asyncComponent from "util/asyncComponent";

const Routes = ({ match }) => (

  <Switch>

  <Route
      path={`${match.url}/emailval`}
      exact
      component={asyncComponent(() => import("../views/emailval"))}
    />
	<Route
      path={`${match.url}/payouts`}
      exact
      component={asyncComponent(() => import("../views/payouts"))}
    />
    <Route
      path={`${match.url}/users`}
      exact
      component={asyncComponent(() => import("../views/users"))}
    />
	<Route
      path={`${match.url}/blockedPatients`}
      exact
      component={asyncComponent(() => import("../views/blockedPatients"))}
    />
	<Route
      path={`${match.url}/admin`}
      exact
      component={asyncComponent(() => import("../views/admin"))}
    />
	<Route
      path={`${match.url}/faq`}
      exact
      component={asyncComponent(() => import("../views/faq"))}
    />
	<Route
      path={`${match.url}/review`}
      exact
      component={asyncComponent(() => import("../views/review"))}
    />
	<Route
      path={`${match.url}/addAdmin`}
      exact
      component={asyncComponent(() => import("../views/addAdmin"))}
    />
    <Route
      path={`${match.url}/doctors`}
      exact
      component={asyncComponent(() => import("../views/doctors"))}
    />
	<Route
      path={`${match.url}/blockedPractitioner`}
      exact
      component={asyncComponent(() => import("../views/blockedPractitioner"))}
    />
	<Route
      path={`${match.url}/approvePractitioner`}
      exact
      component={asyncComponent(() => import("../views/approvePractitioner"))}
    />
	<Route
      path={`${match.url}/underprocessPractitioner`}
      exact
      component={asyncComponent(() => import("../views/underprocessPractitioner"))}
    />
	<Route
      path={`${match.url}/statistics`}
      exact
      component={asyncComponent(() => import("../views/statistics"))}
    />
    <Route
      path={`${match.url}/hospitals`}
      exact
      component={asyncComponent(() => import("../views/hospitals"))}
    />
    <Route
      path={`${match.url}/blogs`}
      exact
      component={asyncComponent(() => import("../views/blogs"))}
    />
    <Route
      path={`${match.url}/companyinfo`}
      exact
      component={asyncComponent(() => import("../views/companyinfo"))}
    />
    <Route
      path={`${match.url}/notifications`}
      exact
      component={asyncComponent(() => import("../views/notifications"))}
    />
    <Route
      path={`${match.url}/profile`}
      exact
      component={asyncComponent(() => import("../views/profile"))}
    />
    <Route
      path={`${match.url}/payments`}
      exact
      component={asyncComponent(() => import("../views/payments"))}
    />
	<Route
      path={`${match.url}/payouts`}
      exact
      component={asyncComponent(() => import("../views/payouts"))}
    />
    <Route
      path={`${match.url}/appointments`}
      exact
      component={asyncComponent(() => import("../views/appointments"))}
    />
    <Route
      path={`${match.url}/about`}
      exact
      component={asyncComponent(() => import("../views/about"))}
    />
	<Route
      path={`${match.url}/privacy`}
      exact
      component={asyncComponent(() => import("../views/privacy"))}
    />
	<Route
      path={`${match.url}/consulttnc`}
      exact
      component={asyncComponent(() => import("../views/consulttnc"))}
    />
	<Route
      path={`${match.url}/standardtnc`}
      exact
      component={asyncComponent(() => import("../views/standardtnc"))}
    />
    {/* Add new data screens */}
    <Route
      path={`${match.url}/addDoctor`}
      exact
      component={asyncComponent(() => import("../views/addDoctor"))}
    />
	<Route
      path={`${match.url}/addFaq`}
      exact
      component={asyncComponent(() => import("../views/addFaq"))}
    />
	<Route
      path={`${match.url}/addDoctorBulk`}
      exact
      component={asyncComponent(() => import("../views/addDoctorBulk"))}
    />
    <Route
      path={`${match.url}/addHospital`}
      exact
      component={asyncComponent(() => import("../views/addHospital"))}
    />
	<Route
      path={`${match.url}/addHospitalBulk`}
      exact
      component={asyncComponent(() => import("../views/addHospitalBulk"))}
    />
    <Route
      path={`${match.url}/addBlog`}
      exact
      component={asyncComponent(() => import("../views/addBlog"))}
    />
    <Route
      path={`${match.url}/addIllness`}
      exact
      component={asyncComponent(() => import("../views/addIllness"))}
    />

    {/* Single containers */}
    <Route
      path={`${match.url}/users/:name`}
      component={asyncComponent(() => import("../views/singleUser"))}
    />
	<Route
      path={`${match.url}/singleFaq/`}
      component={asyncComponent(() => import("../views/singleFaq"))}
    />
	<Route
      path={`${match.url}/singlePatient/`}
      component={asyncComponent(() => import("../views/singlePatient"))}
    />
	<Route
      path={`${match.url}/singlePractitioner/`}
      component={asyncComponent(() => import("../views/singlePractitioner"))}
    />
    <Route
      path={`${match.url}/doctors/:name`}
      component={asyncComponent(() => import("../views/singleDoctor"))}
    />
    <Route
      path={`${match.url}/hospitals/:name`}
      component={asyncComponent(() => import("../views/singleHospital"))}
    />
    <Route
      path={`${match.url}/blogs/:title`}
      component={asyncComponent(() => import("../views/singleBlog"))}
    />
    <Route
      path={`${match.url}/payments/:uid`}
      component={asyncComponent(() => import("../views/singlePayment"))}
    />
    <Route
      path={`${match.url}/appointments/:uid`}
      component={asyncComponent(() => import("../views/singleAppointment"))}
    />
    {/*<Route component={asyncComponent(() => import("app/routes/extraPages/routes/404"))}/>*/}
  </Switch>
);

export default withRouter(Routes);
