import {
  GET_APPOINTMENTDASH_LOADING,
  GET_APPOINTMENTDASH_SUCCESS,
  GET_APPOINTMENTDASH_ERROR
} from "constants/ActionTypes";

const INITIAL_STATE = {
  appointmentdashList: [],
  loading: false,
  error: "",
  lastDocument: null,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_APPOINTMENTDASH_LOADING:
      return {
        ...state,
        loading: action.payload,
      };
      

    case GET_APPOINTMENTDASH_SUCCESS:
      return {
        ...state,
        loading: false,
        appointmentdashList: [...state.appointmentdashList,...action.payload.appointmentdashList],
        lastDocument: action.payload.lastDocument,
      };
      

    case GET_APPOINTMENTDASH_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    default:
      return state;
  }
};
