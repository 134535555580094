/**
 * 
 * Creates a date object from the timestamp given to the functions
 * There are separate funcitons which give different date formats.
 * 
 */
 export default class CreateDate {

static dmyRef() {
    
     
    let newDate = "";

    // Creates a temp Date object to preform operations
    let temp = new Date();
    let day = temp.getDay();
	if(day<10){
		day="0"+day;
	}
    let month = temp.getUTCMonth() + 1;
	if(month<10){
		month="0"+month;
	}
    let year = temp.getYear();
	let hr=temp.getHours();
	let mn=temp.getMinutes();
	let se=temp.getSeconds();
    newDate = `MediVic${day}${month}${year}${hr}${mn}${se}`;

    return newDate;
  }

 static dmyEpoch(timestamp) {
    
     if (timestamp === undefined) {
      return "Not Available";
    }
     
    let newDate = "";

    // Creates a temp Date object to preform operations
    let temp = new Date(timestamp);
    let day = temp.getDay();
	if(day<10){
		day="0"+day;
	}
    let month = temp.getUTCMonth() + 1;
	if(month<10){
		month="0"+month;
	}
    let year = temp.getFullYear();
    newDate = `${day}/${month}/${year}`;

    return newDate;
  }
  
  static dmy(timestamp) {
    
     if (timestamp === undefined) {
      return "Not Available";
    }
     
    let newDate = "";

    // Creates a temp Date object to preform operations
    let temp = new Date(Number(timestamp));

    let day = temp.getUTCDate();
    let month = temp.getUTCMonth() + 1;
    let year = temp.getUTCFullYear();
    newDate = `${day}/${month}/${year}`;

    return newDate;
  }
  
  static month(timestamp) {
    
     if (timestamp === undefined) {
      return "Not Available";
    }
     
    let newDate = "";

    // Creates a temp Date object to preform operations
    let temp = new Date(Number(timestamp));
	let month = temp.getUTCMonth() + 1;
    newDate = `${month}`;

    return newDate;
  }
  
}