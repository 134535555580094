import {
  GET_USERS,
  GET_USERS_ERROR,
  GET_USERS_SUCCESS,
  GET_USERS_LOADING,
  GET_USERS_ROWS_PER_PAGE,
  GET_USERS_PAGE_NUMBER,
  GET_MORE_USERS,
  GET_MORE_USERS_LOADING,
  UPDATE_USER,
} from "constants/ActionTypes";

export const getUsers = () => {
  return {
    type: GET_USERS
  };
};

export const updateUser = userObject => {
  return {
    type: UPDATE_USER,
    payload: userObject,
  }
}

export const getMoreUsers = (lastDocument) => {
  return {
    type: GET_MORE_USERS,
    payload: {
      lastDocument: lastDocument,
    },
  };
};

export const getUsersLoading = value => {
  return {
    type: GET_USERS_LOADING,
    payload: value
  };
};

export const getMoreUsersLoading = value => {
  return {
    type: GET_MORE_USERS_LOADING,
    payload: value
  };
};

export const getUsersError = error => {
  return {
    type: GET_USERS_ERROR,
    payload: error
  };
};

export const getUsersSuccess = (usersList, lastDocument) => {
  return {
    type: GET_USERS_SUCCESS,
    payload: {
      usersList,
      lastDocument,
    }
  };
};

export const setRowsPerPage = (value) => {
  return {
    type: GET_USERS_ROWS_PER_PAGE,
    payload: value
  };
}

export const setPageNumber = (value) => {
  return {
    type: GET_USERS_PAGE_NUMBER,
    payload: value
  };
}



// export const getMoreUsers = () => {
//   return {
//     type: GET_MORE_USERS
//   };
// };

// export const getSingleUser = () => {
//   return {
//     type: GET_SINGLE_USER
//   };
// };
