const WEB_API =	"https://us-central1-medivic-c93ac.cloudfunctions.net/webApi/api/v1";
export const BROADCAST_TOPIC = "TO_ALL_USERS";
export const BROADCAST_NOTIFICATION_URL =
  WEB_API + "/sendBoradcastNotification";
export const CREATE_DOCTOR_URL = WEB_API + "/createDoctor";
export const DELETE_DOCTOR_URL = WEB_API + "/deleteDoctor";
export const CREATE_ADMIN_URL = WEB_API + "/createAdmin";
export const CURRENCY = 'R';

export const PLACEHOLDER_IMAGE_URL =  "https://firebasestorage.googleapis.com/v0/b/medivic-c93ac.appspot.com/o/placeholder-img.jpg?alt=media&token=bde1ba67-359e-4816-93e3-69c12cadcce0";

// Initialize Firebase settings
export const FIREBASE_CONFIG = {
	 apiKey: "AIzaSyClduRGDITIuyQ2z8xjh-w1ktN5vG8zzRg",
    authDomain: "medivic-c93ac.firebaseapp.com",
    databaseURL: "https://medivic-c93ac.firebaseio.com",
    projectId: "medivic-c93ac",
    storageBucket: "medivic-c93ac.appspot.com",
    messagingSenderId: "805286912092",
    appId: "1:805286912092:web:439e6c5ac6471a91ffc699",
  measurementId:  "G-JYSH591YF0"
};

