import {
  GET_APPOINTMENTS_LOADING,
  GET_APPOINTMENTS_SUCCESS,
  GET_APPOINTMENTS_ERROR,
  GET_APPOINTMENTS_ROWS_PER_PAGE,
  GET_APPOINTMENTS_PAGE_NUMBER,
  GET_MORE_APPOINTMENTS_LOADING
} from "constants/ActionTypes";

const INITIAL_STATE = {
  appointmentsList: [],
  loading: false,
  singleAppointmentLoading: false,
  error: "",
  rowsPerPage: 10,
  page: 0,
  moreAppointmentsLoading: false,
  lastDocument: null,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_APPOINTMENTS_LOADING:
      return {
        ...state,
        loading: action.payload,
      };

    case GET_MORE_APPOINTMENTS_LOADING:
      return {
        ...state,
        moreAppointmentsLoading: action.payload,
      };
      

    case GET_APPOINTMENTS_SUCCESS:
      return {
        ...state,
        loading: false,
        moreAppointmentsLoading: false,
        appointmentsList: [...state.appointmentsList,...action.payload.appointmentsList],
        lastDocument: action.payload.lastDocument,
      };
      

    case GET_APPOINTMENTS_ERROR:
      return {
        ...state,
        loading: false,
        moreAppointmentsLoading: false,
        error: action.payload,
      };
      

    case GET_APPOINTMENTS_ROWS_PER_PAGE:
      return {
        ...state,
        rowsPerPage: action.payload,
      };
      

    case GET_APPOINTMENTS_PAGE_NUMBER:
      return {
        ...state,
        page: action.payload,
      };
      

    default:
      return state;
  }
};
