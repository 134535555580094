import {
  GET_APPOINTMENTDASH,
  GET_APPOINTMENTDASH_ERROR,
  GET_APPOINTMENTDASH_SUCCESS,
  GET_APPOINTMENTDASH_LOADING,
} from "../constants/ActionTypes";

export const getAppointmentDash = () => {
  return {
    type: GET_APPOINTMENTDASH,
  };
};

export const getAppointmentDashLoading = (value) => {
  return {
    type: GET_APPOINTMENTDASH_LOADING,
    payload: value,
  };
};

export const getAppointmentdashError = (error) => {
  return {
    type: GET_APPOINTMENTDASH_ERROR,
    payload: error,
  };
};

export const getAppointmentdashSuccess = (appointmentdashList, lastDocument) => {
  return {
    type: GET_APPOINTMENTDASH_SUCCESS,
    payload: {
      appointmentdashList,
      lastDocument,
    },
  };
};