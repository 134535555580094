import {
  GET_ADMIN,
  GET_ADMIN_ERROR,
  GET_ADMIN_SUCCESS,
  GET_ADMIN_LOADING,
  GET_ADMIN_ROWS_PER_PAGE,
  GET_ADMIN_PAGE_NUMBER,
} from "constants/ActionTypes";

export const getAdmin = () => {
  return {
    type: GET_ADMIN
  };
};


export const getAdminLoading = value => {
  return {
    type: GET_ADMIN_LOADING,
    payload: value
  };
};


export const getAdminError = error => {
  return {
    type: GET_ADMIN_ERROR,
    payload: error
  };
};

export const getAdminSuccess = (adminList, lastDocument) => {
  return {
    type: GET_ADMIN_SUCCESS,
    payload: {
      adminList,
      lastDocument
    }
  };
};

export const setRowsPerPage = value => {
  return {
    type: GET_ADMIN_ROWS_PER_PAGE,
    payload: value
  };
};

export const setPageNumber = value => {
  return {
    type: GET_ADMIN_PAGE_NUMBER,
    payload: value
  };
};

