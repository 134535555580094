import {
  GET_FAQ,
  GET_FAQ_ERROR,
  GET_FAQ_SUCCESS,
  GET_FAQ_LOADING,
  GET_FAQ_ROWS_PER_PAGE,
  GET_FAQ_PAGE_NUMBER,
  UPDATE_FAQ,
  DELETE_FAQ,
  ADD_FAQ,
  GET_MORE_FAQ,
  GET_MORE_FAQ_LOADING,
} from "constants/ActionTypes";

export const getFaq = () => {
  return {
    type: GET_FAQ
  };
};

export const getMoreFaq = (lastDocument) => {
  return {
    type: GET_MORE_FAQ,
    payload: {
      lastDocument: lastDocument,
    }
  };
};

export const getFaqLoading = value => {
  return {
    type: GET_FAQ_LOADING,
    payload: value
  };
};

export const getMoreFaqLoading = value => {
  return {
    type: GET_MORE_FAQ_LOADING,
    payload: value
  };
};

export const getFaqError = error => {
  return {
    type: GET_FAQ_ERROR,
    payload: error
  };
};

export const getFaqSuccess = (faqList, lastDocument) => {
  return {
    type: GET_FAQ_SUCCESS,
    payload: {
      faqList,
      lastDocument
    }
  };
};

export const setRowsPerPage = value => {
  return {
    type: GET_FAQ_ROWS_PER_PAGE,
    payload: value
  };
};

export const setPageNumber = value => {
  return {
    type: GET_FAQ_PAGE_NUMBER,
    payload: value
  };
};

export const updateFaq = faqObject => {
  return {
    type: UPDATE_FAQ,
    payload: faqObject
  };
};

export const addFaq = faqObject => {
  return {
    type: ADD_FAQ,
    payload: faqObject
  };
};

export const deleteFaq = faqObject => {
  return {
    type: DELETE_FAQ,
    payload: faqObject
  };
};
