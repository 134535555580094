import {
  GET_FAQ_LOADING,
  GET_FAQ_SUCCESS,
  GET_FAQ_ERROR,
  GET_FAQ_ROWS_PER_PAGE,
  GET_FAQ_PAGE_NUMBER,
  UPDATE_FAQ,
  DELETE_FAQ,
  ADD_FAQ,
  GET_MORE_FAQ_LOADING
} from "constants/ActionTypes";

const INITIAL_STATE = {
  faqList: [],
  loading: false,
  error: "",
  rowsPerPage: 10,
  page: 0,
  lastDocument: null,
  moreFaqLoading: false,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_FAQ_LOADING:
      return {
        ...state,
        loading: action.payload
      };

    case GET_MORE_FAQ_LOADING:
      return {
        ...state,
        moreFaqLoading: action.payload,
      };
      

    case GET_FAQ_SUCCESS:
      return {
        ...state,
        loading: false,
        moreFaqLoading: false,
        faqList: [...state.faqList,...action.payload.faqList],
        lastDocument: action.payload.lastDocument,
      };
      

    case GET_FAQ_ERROR:
      return {
        ...state,
        loading: false,
        moreFaqLoading: false,
        error: action.payload
      };
      

    case GET_FAQ_ROWS_PER_PAGE:
      return {
        ...state,
        rowsPerPage: action.payload
      };
      

    case GET_FAQ_PAGE_NUMBER:
      return {
        ...state,
        page: action.payload
      };
      

    case UPDATE_FAQ:
      let tempList = state.faqList.map(elem => {
        return elem.uid === action.payload.uid ? action.payload : elem;
      });
      return {
        ...state,
        faqList: [...tempList]
      };
      

    case ADD_FAQ:
      return {
        ...state,
        faqList: [...state.faqList, action.payload]
      };
      

    case DELETE_FAQ:
      let tempListDelete = state.faqList.filter(elem => {
        return elem.uid !== action.payload.uid;
      });
      return {
        ...state,
        faqList: [...tempListDelete]
      };
      

    default:
      return state;
  }
};
