import { all, call, fork, put, takeEvery, takeLatest } from "redux-saga/effects";
import { GET_PAYOUTS, GET_MORE_PAYOUTS } from "../constants/ActionTypes";
import { getPayoutsError, getPayoutsSuccess } from "actions";
import FirestoreService from "services/firestoreService";

function* getPayoutsHelper() {
  try {
    const result = yield call(FirestoreService.getPayouts);
    if (result.data !== undefined) {
      // It means there is  data to display
      yield put(getPayoutsSuccess(result.data, result.lastDocument));
    } else if (result.noData !== undefined) {
      // It means there is no data to display
      // console.log(result.noData);
      yield put(getPayoutsError(result.noData));
    } else {
      // It means there is error
      // console.log(result.error);
      yield put(getPayoutsError(result.error));
    }
  } catch (e) {
    console.log(e);
  }
}


export function* getPayoutsSaga() {
  yield takeEvery(GET_PAYOUTS, getPayoutsHelper);
}


export default function* rootSaga() {
  yield all([fork(getPayoutsSaga)]);
}
