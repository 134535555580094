import {
  GET_APPOINTMENTS,
  GET_APPOINTMENTS_ERROR,
  GET_APPOINTMENTS_SUCCESS,
  GET_APPOINTMENTS_LOADING,
  GET_APPOINTMENTS_ROWS_PER_PAGE,
  GET_APPOINTMENTS_PAGE_NUMBER,
  GET_MORE_APPOINTMENTS,
  GET_MORE_APPOINTMENTS_LOADING,
} from "constants/ActionTypes";

export const getAppointments = () => {
  return {
    type: GET_APPOINTMENTS,
  };
};

export const getMoreAppointments = (lastDocument) => {  
  return {
    type: GET_MORE_APPOINTMENTS,
    payload: {
      lastDocument: lastDocument,
    },
  };
};

export const getAppointmentsLoading = (value) => {
  return {
    type: GET_APPOINTMENTS_LOADING,
    payload: value,
  };
};

export const getMoreAppointmentsLoading = (value) => {
  return {
    type: GET_MORE_APPOINTMENTS_LOADING,
    payload: value,
  };
};

export const getAppointmentsError = (error) => {
  return {
    type: GET_APPOINTMENTS_ERROR,
    payload: error,
  };
};

export const getAppointmentsSuccess = (appointmentsList, lastDocument) => {
  return {
    type: GET_APPOINTMENTS_SUCCESS,
    payload: {
      appointmentsList,
      lastDocument,
    },
  };
};

export const setRowsPerPage = (value) => {
  return {
    type: GET_APPOINTMENTS_ROWS_PER_PAGE,
    payload: value,
  };
};

export const setPageNumber = (value) => {
  return {
    type: GET_APPOINTMENTS_PAGE_NUMBER,
    payload: value,
  };
};