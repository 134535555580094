import {
  GET_PAYOUTS,
  GET_PAYOUTS_ERROR,
  GET_PAYOUTS_SUCCESS,
  GET_PAYOUTS_LOADING,
  GET_PAYOUTS_ROWS_PER_PAGE,
  GET_PAYOUTS_PAGE_NUMBER,
} from "constants/ActionTypes";

export const getPayouts = () => {
  return {
    type: GET_PAYOUTS,
  };
};


export const getPayoutsLoading = (value) => {
  return {
    type: GET_PAYOUTS_LOADING,
    payload: value,
  };
};

export const getPayoutsError = (error) => {
  return {
    type: GET_PAYOUTS_ERROR,
    payload: error,
  };
};

export const getPayoutsSuccess = (payoutsList, lastDocument) => {
  return {
    type: GET_PAYOUTS_SUCCESS,
    payload: {
      payoutsList,
      lastDocument,
    },
  };
};

export const setRowsPerPage = (value) => {
  return {
    type: GET_PAYOUTS_ROWS_PER_PAGE,
    payload: value,
  };
};

export const setPageNumber = (value) => {
  return {
    type: GET_PAYOUTS_PAGE_NUMBER,
    payload: value,
  };
};
