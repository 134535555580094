import {
  GET_ADMIN_LOADING,
  GET_ADMIN_SUCCESS,
  GET_ADMIN_ERROR,
  GET_ADMIN_ROWS_PER_PAGE,
  GET_ADMIN_PAGE_NUMBER
  } from "constants/ActionTypes";

const INITIAL_STATE = {
  adminList: [],
  loading: false,
  error: "",
  rowsPerPage: 10,
  page: 0,
  lastDocument: null,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_ADMIN_LOADING:
      return {
        ...state,
        loading: action.payload
      };
	case GET_ADMIN_SUCCESS:
      return {
        ...state,
        loading: false,
        adminList: [...state.adminList,...action.payload.adminList],
        lastDocument: action.payload.lastDocument,
      };
      

    case GET_ADMIN_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload
      };
      

    case GET_ADMIN_ROWS_PER_PAGE:
      return {
        ...state,
        rowsPerPage: action.payload
      };
      

    case GET_ADMIN_PAGE_NUMBER:
      return {
        ...state,
        page: action.payload
      };
      
	  
    default:
      return state;
  }
};
