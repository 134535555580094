import { all, call, fork, put, takeEvery, takeLatest } from "redux-saga/effects";
import { GET_APPOINTMENTDASH } from "../constants/ActionTypes";
import { getAppointmentdashError, getAppointmentdashSuccess } from "actions";
import FirestoreService from "services/firestoreService";

function* getAppointmentsHelper() {
  try {
    const result = yield call(FirestoreService.getAppointmentDash);
    if (result.data !== undefined) {
      // It means there is  data to display
      yield put(getAppointmentdashSuccess(result.data, result.lastDocument));
    } else if (result.noData !== undefined) {
      // It means there is no data to display
      // console.log(result.noData);
      yield put(getAppointmentdashError(result.noData));
    } else {
      // It means there is error
      // console.log(result.error);
      yield put(getAppointmentdashError(result.error));
    }
  } catch (e) {
    console.log(e);
  }
}

export function* getAppointmentdashSaga() {
  yield takeEvery(GET_APPOINTMENTDASH, getAppointmentsHelper);
}

export default function* rootSaga() {
  yield all([fork(getAppointmentdashSaga)]);
}
