import { all, call, fork, put, takeEvery, takeLatest } from "redux-saga/effects";
import { GET_COMPANYINFO } from "../constants/ActionTypes";
import { getCompanyInfoError, getCompanyInfoSuccess } from "actions";
import FirestoreService from "services/firestoreService";

function* getCompanyInfoHelper() {
  try {
    const result = yield call(FirestoreService.getCompanyInfo);
    if (result.data !== undefined) {
      // It means there is  data to display
      yield put(getCompanyInfoSuccess(result.data, result.lastDocument));
    } else if (result.noData !== undefined) {
      // It means there is no data to display
      // console.log(result.noData);
      yield put(getCompanyInfoError(result.noData));
    } else {
      // It means there is error
      // console.log(result.error);
      yield put(getCompanyInfoError(result.error));
    }
  } catch (e) {
    console.log(e);
  }
}

export function* getCompanyinfoSaga() {
  yield takeEvery(GET_COMPANYINFO, getCompanyInfoHelper);
}

export default function* rootSaga() {
  yield all([fork(getCompanyinfoSaga)]);
}
