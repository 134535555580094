import { all, call, fork, put, takeEvery, takeLatest } from "redux-saga/effects";
import { GET_PAYMENTS, GET_MORE_PAYMENTS } from "../constants/ActionTypes";
import { getPaymentsError, getPaymentsSuccess } from "actions";
import FirestoreService from "services/firestoreService";

function* getPaymentsHelper() {
  try {
    const result = yield call(FirestoreService.getPayments);
    if (result.data !== undefined) {
      // It means there is  data to display
      yield put(getPaymentsSuccess(result.data, result.lastDocument));
    } else if (result.noData !== undefined) {
      // It means there is no data to display
      // console.log(result.noData);
      yield put(getPaymentsError(result.noData));
    } else {
      // It means there is error
      // console.log(result.error);
      yield put(getPaymentsError(result.error));
    }
  } catch (e) {
    console.log(e);
  }
}

function* getMorePaymentsHelper({payload}) {
  try {
    const result = yield call(FirestoreService.getMorePayments, payload.lastDocument);
    if (result.data !== undefined) {
      // It means there is  data to display
      yield put(getPaymentsSuccess(result.data, result.lastDocument));
    } else if (result.noData !== undefined) {
      // It means there is no data to display
      // console.log(result.noData);
      yield put(getPaymentsError(result.noData));
    } else {
      // It means there is error
      // console.log(result.error);
      yield put(getPaymentsError(result.error));
    }
  } catch (e) {
    console.log(e);
  }
}

export function* getPaymentsSaga() {
  yield takeEvery(GET_PAYMENTS, getPaymentsHelper);
}

export function* getMorePaymentsSaga() {
  yield takeLatest(GET_MORE_PAYMENTS, getMorePaymentsHelper);
}

export default function* rootSaga() {
  yield all([fork(getPaymentsSaga), fork(getMorePaymentsSaga)]);
}
