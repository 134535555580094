import {
  GET_HOSPITALS,
  GET_HOSPITALS_ERROR,
  GET_HOSPITALS_SUCCESS,
  GET_HOSPITALS_LOADING,
  GET_HOSPITALS_ROWS_PER_PAGE,
  GET_HOSPITALS_PAGE_NUMBER,
  UPDATE_HOSPITAL,
  DELETE_HOSPITAL,
  ADD_HOSPITAL,
  GET_MORE_HOSPITALS,
  GET_MORE_HOSPITALS_LOADING,
} from "../constants/ActionTypes";

export const getHospitals = () => {
  return {
    type: GET_HOSPITALS
  };
};

export const getMoreHospitals = (lastDocument) => {
  return {
    type: GET_MORE_HOSPITALS,
    payload: {
      lastDocument: lastDocument,
    }
  };
};

export const getHospitalsLoading = value => {
  return {
    type: GET_HOSPITALS_LOADING,
    payload: value
  };
};

export const getMoreHospitalsLoading = value => {
  return {
    type: GET_MORE_HOSPITALS_LOADING,
    payload: value
  };
};

export const getHospitalsError = error => {
  return {
    type: GET_HOSPITALS_ERROR,
    payload: error
  };
};

export const getHospitalsSuccess = (hospitalsList, lastDocument) => {
  return {
    type: GET_HOSPITALS_SUCCESS,
    payload: {
      hospitalsList,
      lastDocument
    }
  };
};

export const setRowsPerPage = value => {
  return {
    type: GET_HOSPITALS_ROWS_PER_PAGE,
    payload: value
  };
};

export const setPageNumber = value => {
  return {
    type: GET_HOSPITALS_PAGE_NUMBER,
    payload: value
  };
};

export const updateHospital = hospitalObject => {
  return {
    type: UPDATE_HOSPITAL,
    payload: hospitalObject,
  }
}

export const addHospital = hospitalObject => {
  return {
    type: ADD_HOSPITAL,
    payload: hospitalObject,
  }
}

export const deleteHospital = hospitalObject => {
  return {
    type: DELETE_HOSPITAL,
    payload: hospitalObject,
  }
}

// export const getMoreHospitals = () => {
//   return {
//     type: GET_MORE_HOSPITALS
//   };
// };

// export const getSingleHospitals = () => {
//   return {
//     type: GET_SINGLE_HOSPITAL
//   };
// };
