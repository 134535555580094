import { all, call, fork, put, takeEvery, takeLatest } from "redux-saga/effects";
import { GET_ADMIN } from "../constants/ActionTypes";
import { getAdminError, getAdminSuccess } from "../actions/Admin";
import FirestoreService from "../services/firestoreService";

function* getAdminHelper() {
  try {
    const result = yield call(FirestoreService.getAdmins);
    if (result.data !== undefined) {
      // It means there is  data to display
      yield put(getAdminSuccess(result.data, result.lastDocument));
    } else if (result.noData !== undefined) {
      // It means there is no data to display
      // console.log(result.noData);
      yield put(getAdminError(result.noData));
    } else {
      // It means there is error
      // console.log(result.error);
      yield put(getAdminError(result.error));
    }
  } catch (e) {
    console.log(e);
  }
}


export function* getAdminSaga() {
  yield takeEvery(GET_ADMIN, getAdminHelper);
}



export default function* rootSaga() {
  yield all([fork(getAdminSaga)]);
}
