import {
  GET_USERS_LOADING,
  GET_USERS_SUCCESS,
  GET_USERS_ERROR,
  GET_USERS_ROWS_PER_PAGE,
  GET_USERS_PAGE_NUMBER,
  GET_MORE_USERS_LOADING,
} from "../constants/ActionTypes";

const INITIAL_STATE = {
  usersList: [],
  singleUserId: "",
  singleUserMap: {},
  loading: false,
  singleUserLoading: false,
  error: "",
  rowsPerPage: 10,
  page: 0,
  // Keeps track of the next query cursor start point.
  lastDocument: null,
  moreUsersLoading: false,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_USERS_LOADING:
      return {
        ...state,
        loading: action.payload
      };

    case GET_MORE_USERS_LOADING:
      return {
        ...state,
        moreUsersLoading: action.payload,
      };
      

    case GET_USERS_SUCCESS:
      return {
        ...state,
        loading: false,
        moreUsersLoading: false,
        usersList: [...state.usersList, ...action.payload.usersList],
        lastDocument: action.payload.lastDocument,
      };

    case GET_USERS_ERROR:
      return {
        ...state,
        loading: false,
        moreUsersLoading: false,
        error: action.payload,
      };
      

    case GET_USERS_ROWS_PER_PAGE:
      return {
        ...state,
        rowsPerPage: action.payload
      };
      

    case GET_USERS_PAGE_NUMBER:
      return {
        ...state,
        page: action.payload
      };
      

    default:
      return state;
  }
};
