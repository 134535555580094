import React, {useState} from 'react';
import Avatar from '@material-ui/core/Avatar'
import {useDispatch} from 'react-redux'
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import {userSignOut} from 'actions/Auth';
import IntlMessages from 'util/IntlMessages';
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";

import PentagonImage from "assets/images/pentagon.png";

const UserInfo = () => {

  const dispatch = useDispatch();

  const [anchorE1, setAnchorE1] = useState(null);
  const [open, setOpen] = useState(false);

  const handleClick = event => {
    setOpen(true);
    setAnchorE1(event.currentTarget);
  };

  const handleRequestClose = () => {
    setOpen(false);
  };

  return (
    <div className="user-profile d-flex flex-row align-items-center">
      <Box height={44}>
        
		<div className="tableHead-h6 text-white">
			   MediVic Admin Panel
		</div>
      </Box>
    </div>
  );
};

export default UserInfo;


