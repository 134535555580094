import React, {Component} from 'react';
import {NavLink, withRouter} from 'react-router-dom';

import IntlMessages from 'util/IntlMessages';
import CustomScrollbars from 'util/CustomScrollbars';


class SidenavContent extends Component {
	
  componentDidMount() {
    const {history} = this.props;
    const that = this;
    const pathname = `${history.location.pathname}`;// get current path

    const menuLi = document.getElementsByClassName('menu');
    for (let i = 0; i < menuLi.length; i++) {
      menuLi[i].onclick = function (event) {

        const parentLiEle = that.closest(this, 'li');
        if (menuLi[i].classList.contains('menu') && parentLiEle !== null) {
          event.stopPropagation();

          if (menuLi[i].classList.contains('open')) {
            menuLi[i].classList.remove('open', 'active');
          } else {
            menuLi[i].classList.add('open', 'active');
          }
        } else {
          for (let j = 0; j < menuLi.length; j++) {
            const parentLi = that.closest(this, 'li');
            if (menuLi[j] !== this && (parentLi === null || !parentLi.classList.contains('open'))) {
              menuLi[j].classList.remove('open');
            } else {
              if (menuLi[j].classList.contains('open')) {
                menuLi[j].classList.remove('open');
              } else {
                menuLi[j].classList.add('open');
              }
            }
          }
        }
      }
    }

    const activeLi = document.querySelector('a[href="' + pathname + '"]');// select current a element
    try {
      const activeNav = this.closest(activeLi, 'ul'); // select closest ul
      if (activeNav.classList.contains('sub-menu')) {
        this.closest(activeNav, 'li').classList.add('open');
      } else {
        this.closest(activeLi, 'li').classList.add('open');
      }
    } catch (error) {

    }
  }

  componentWillReceiveProps(nextProps) {
    const {history} = nextProps;
    const pathname = `${history.location.pathname}`;// get current path

    const activeLi = document.querySelector('a[href="' + pathname + '"]');// select current a element
    try {
      const activeNav = this.closest(activeLi, 'ul'); // select closest ul
      if (activeNav.classList.contains('sub-menu')) {
        this.closest(activeNav, 'li').classList.add('open');
      } else {
        this.closest(activeLi, 'li').classList.add('open');
      }
    } catch (error) {

    }
  }

  closest(el, selector) {
    try {
      let matchesFn;
      // find vendor prefix
      ['matches', 'webkitMatchesSelector', 'mozMatchesSelector', 'msMatchesSelector', 'oMatchesSelector'].some(function (fn) {
        if (typeof document.body[fn] === 'function') {
          matchesFn = fn;
          return true;
        }
        return false;
      });

      let parent;

      // traverse parents
      while (el) {
        parent = el.parentElement;
        if (parent && parent[matchesFn](selector)) {
          return parent;
        }
        el = parent;
      }
    } catch (e) {

    }

    return null;
  }

  render() {
	  let role=localStorage.getItem("role");
	  
    return (
	
      <CustomScrollbars className="scrollbar">
	  <a href="/app/statistics"><img width="100%" src="https://firebasestorage.googleapis.com/v0/b/medivic-c93ac.appspot.com/o/medivic_logo.png?alt=media&token=a0227046-2410-4f53-ab9f-be482afd140d"></img></a>
        <ul className="nav-menu">
			{role=='admin'?(
		  <span>
          <li className="nav-header">
            <IntlMessages id="Admin" />
          </li>
          <li className="menu no-arrow">
            <NavLink to="/app/admin">
              <i className="zmdi zmdi-hc-fw" >&#9825;</i>
              <span className="nav-text">
                <IntlMessages id="View All" />{" "}
              </span>
            </NavLink>
          </li>
		  <li className="menu no-arrow">
            <NavLink to="/app/addAdmin">
              <i className="zmdi zmdi-hc-fw" >&#9825;</i>
              <span className="nav-text">
                <IntlMessages id="Add Admin" />{" "}
              </span>
            </NavLink>
          </li>
		  </span>
		  ):(<span></span>)}
			{role=='admin' || role=='basicadmin'?(
			<span>
		  <li className="nav-header">
            <IntlMessages id="Patients" />
          </li>
		  <li className="menu no-arrow">
            <NavLink to="/app/users">
              <i className="zmdi zmdi-hc-fw" >&#9825;</i>
              <span className="nav-text">
                <IntlMessages id="View Active" />{" "}
              </span>
            </NavLink>
          </li>
          <li className="menu no-arrow">
            <NavLink to="/app/blockedPatients">
              <i className="zmdi zmdi-hc-fw" >&#9825;</i>
              <span className="nav-text">
                <IntlMessages id="View Blocked" />{" "}
              </span>
            </NavLink>
          </li>
		  <li className="nav-header">
            <IntlMessages id="Practitioners" />
          </li>
		  <li className="menu no-arrow">
            <NavLink to="/app/doctors">
              <i className="zmdi zmdi-hc-fw" >&#9825;</i>
              <span className="nav-text">
				<IntlMessages id="View Active" />{" "}
              </span>
            </NavLink>
          </li>
		  <li className="menu no-arrow">
            <NavLink to="/app/blockedPractitioner">
              <i className="zmdi zmdi-hc-fw" >&#9825;</i>
              <span className="nav-text">
                <IntlMessages id="View Blocked" />{" "}
              </span>
            </NavLink>
          </li>
          <li className="menu no-arrow">
            <NavLink to="/app/approvepractitioner">
              <i className="zmdi zmdi-hc-fw" >&#9825;</i>
              <span className="nav-text">
                <IntlMessages id="Approval Pending" />{" "}
              </span>
            </NavLink>
          </li>
		  <li className="menu no-arrow">
            <NavLink to="/app/underprocessPractitioner">
              <i className="zmdi zmdi-hc-fw" >&#9825;</i>
              <span className="nav-text">
                <IntlMessages id="Under Processing" />{" "}
              </span>
            </NavLink>
          </li>
		  <li className="nav-header">
            <IntlMessages id="OTHER CATEGORIES" />
          </li>
		  <li className="menu no-arrow">
            <NavLink to="/app/hospitals">
              <i className="zmdi zmdi-hc-fw" >&#9825;</i>
              <span className="nav-text">
                <IntlMessages id="pharmacies" />{" "}
              </span>
            </NavLink>
          </li>
          <li className="menu no-arrow">
            <NavLink to="/app/companyinfo">
              <i className="zmdi zmdi-hc-fw" >&#9825;</i>
              <span className="nav-text">
                <IntlMessages id="Company Info" />{" "}
              </span>
            </NavLink>
          </li>
          <li className="menu no-arrow">
            <NavLink to="/app/blogs">
              <i className="zmdi zmdi-hc-fw" >&#9825;</i>
              <span className="nav-text">
                <IntlMessages id="blogs" />{" "}
              </span>
            </NavLink>
          </li>
          <li className="menu no-arrow">
            <NavLink to="/app/notifications">
              <i className="zmdi zmdi-hc-fw" >&#9825;</i>
              <span className="nav-text">
                <IntlMessages id="notifications" />{" "}
              </span>
            </NavLink>
          </li>
          <li className="menu no-arrow">
            <NavLink to="/app/profile">
              <i className="zmdi zmdi-hc-fw" >&#9825;</i>
              <span className="nav-text">
                <IntlMessages id="profile" />{" "}
              </span>
            </NavLink>
          </li>
		  <li className="menu no-arrow">
            <NavLink to="/app/faq">
              <i className="zmdi zmdi-hc-fw" >&#9825;</i>
              <span className="nav-text">
                <IntlMessages id="FAQ" />{" "}
              </span>
            </NavLink>
          </li>
          
		  </span>
		  ):(
		  <span></span>
		  )}
		  
		  <li className="nav-header">
            <IntlMessages id="STATS & LOGS" />
          </li>
          {role=='admin' || role=='finadmin'?(
		  <span>	  

          <li className="menu no-arrow">
            <NavLink to="/app/appointments">
              <i className="zmdi zmdi-hc-fw" >&#9825;</i>
              <span className="nav-text">
                <IntlMessages id="sidebar.appointments" />{" "}
              </span>
            </NavLink>
          </li>
		  
		  <li className="menu no-arrow">
            <NavLink to="/app/statistics">
              <i className="zmdi zmdi-hc-fw" >&#9825;</i>
              <span className="nav-text">
                <IntlMessages id="Statistics" />{" "}
              </span>
            </NavLink>
          </li>
          <li className="menu no-arrow">
            <NavLink to="/app/payments">
              <i className="zmdi zmdi-hc-fw" >&#9825;</i>
              <span className="nav-text">
                <IntlMessages id="sidebar.payments" />{" "}
              </span>
            </NavLink>
          </li>
		  <li className="menu no-arrow">
            <NavLink to="/app/payouts">
              <i className="zmdi zmdi-hc-fw" >&#9825;</i>
              <span className="nav-text">
                <IntlMessages id="Practitioner Payout" />{" "}
              </span>
            </NavLink>
          </li>
		  </span>
		  ):(
			<li className="menu no-arrow">
            <NavLink to="/app/statistics">
              <i className="zmdi zmdi-hc-fw" >&#9825;</i>
              <span className="nav-text">
                <IntlMessages id="Statistics" />{" "}
              </span>
            </NavLink>
          </li>
		  )}
		  
		  {role=='admin' || role=='basicadmin'?(
		  <span>
          <li className="nav-header">
            <IntlMessages id="sidebar.addHeading" />
          </li>
		  
		  <li className="menu no-arrow">
            <NavLink to="/app/addHospital">
              <i className="zmdi zmdi-hc-fw" >&#9825;</i>
              <span className="nav-text">
                <IntlMessages id="Add Pharmacy" />{" "}
              </span>
            </NavLink>
          </li>
		  <li className="menu no-arrow">
            <NavLink to="/app/addHospitalBulk">
              <i className="zmdi zmdi-hc-fw" >&#9825;</i>
              <span className="nav-text">
                <IntlMessages id="Add Pharmacies - Bulk" />{" "}
              </span>
            </NavLink>
          </li>
          <li className="menu no-arrow">
            <NavLink to="/app/addBlog">
              <i className="zmdi zmdi-hc-fw" >&#9825;</i>
              <span className="nav-text">
                <IntlMessages id="sidebar.addBlog" />{" "}
              </span>
            </NavLink>
          </li>
          <li className="menu no-arrow">
            <NavLink to="/app/addFaq">
              <i className="zmdi zmdi-hc-fw" >&#9825;</i>
              <span className="nav-text">
                <IntlMessages id="Add FAQ" />{" "}
              </span>
            </NavLink>
          </li>
          </span>
		  ):(<span></span>)}
        </ul>
      </CustomScrollbars>
    );
  }
}

export default withRouter(SidenavContent);
