import {
  GET_PAYMENTS_LOADING,
  GET_PAYMENTS_SUCCESS,
  GET_PAYMENTS_ERROR,
  GET_PAYMENTS_ROWS_PER_PAGE,
  GET_PAYMENTS_PAGE_NUMBER,
  GET_MORE_PAYMENTS_LOADING,
} from "constants/ActionTypes";

const INITIAL_STATE = {
  paymentsList: [],
  loading: false,
  singleAppointmentLoading: false,
  error: "",
  rowsPerPage: 10,
  page: 0,
  lastDocument: null,
  morePaymentsLoading: false,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_PAYMENTS_LOADING:
      return {
        ...state,
        loading: action.payload,
      };

    case GET_MORE_PAYMENTS_LOADING:
      return {
        ...state,
        morePaymentsLoading: action.payload,
      };
      

    case GET_PAYMENTS_SUCCESS:
      return {
        ...state,
        loading: false,
        morePaymentsLoading: false,
        paymentsList: [...state.paymentsList,...action.payload.paymentsList],
        lastDocument: action.payload.lastDocument,
      };
      
    case GET_PAYMENTS_ERROR:
      return {
        ...state,
        loading: false,
        morePaymentsLoading: false,
        error: action.payload,
      };
      

    case GET_PAYMENTS_ROWS_PER_PAGE:
      return {
        ...state,
        rowsPerPage: action.payload,
      };
      

    case GET_PAYMENTS_PAGE_NUMBER:
      return {
        ...state,
        page: action.payload,
      };
      

    default:
      return state;
  }
};
