import {
  GET_PAYOUTS_LOADING,
  GET_PAYOUTS_SUCCESS,
  GET_PAYOUTS_ERROR,
  GET_PAYOUTS_ROWS_PER_PAGE,
  GET_PAYOUTS_PAGE_NUMBER,
} from "constants/ActionTypes";

const INITIAL_STATE = {
  payoutsList: [],
  loading: false,
  singleAppointmentLoading: false,
  error: "",
  rowsPerPage: 10,
  page: 0,
  lastDocument: null,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_PAYOUTS_LOADING:
      return {
        ...state,
        loading: action.payload,
      };


    case GET_PAYOUTS_SUCCESS:
      return {
        ...state,
        loading: false,
        payoutsList: [...state.payoutsList,...action.payload.payoutsList],
        lastDocument: action.payload.lastDocument,
      };
      
    case GET_PAYOUTS_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
      

    case GET_PAYOUTS_ROWS_PER_PAGE:
      return {
        ...state,
        rowsPerPage: action.payload,
      };
      

    case GET_PAYOUTS_PAGE_NUMBER:
      return {
        ...state,
        page: action.payload,
      };
      

    default:
      return state;
  }
};
