import {
  GET_COMPANYINFO_LOADING,
  GET_COMPANYINFO_SUCCESS,
  GET_COMPANYINFO_ERROR
} from "constants/ActionTypes";

const INITIAL_STATE = {
  companyInfoList: [],
  loading: false,
  error: "",
  lastDocument: null,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_COMPANYINFO_LOADING:
      return {
        ...state,
        loading: action.payload,
      };
      

    case GET_COMPANYINFO_SUCCESS:
      return {
        ...state,
        loading: false,
        companyInfoList: [...state.companyInfoList,...action.payload.companyInfoList],
        lastDocument: action.payload.lastDocument,
      };
      

    case GET_COMPANYINFO_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    default:
      return state;
  }
};
