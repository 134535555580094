import { all, call, fork, put, takeEvery, takeLatest } from "redux-saga/effects";
import { GET_HTMLCONTENT, GET_MORE_HTMLCONTENT } from "../constants/ActionTypes";
import { getHtmlcontentError, getHtmlcontentSuccess } from "../actions/Htmlcontent";
import FirestoreService from "../services/firestoreService";

function* getHtmlcontentHelper() {
  try {
    const result = yield call(FirestoreService.getHtmlcontent);
    if (result.data !== undefined) {
      // It means there is  data to display
      // console.log(result.data)
      yield put(getHtmlcontentSuccess(result.data, result.lastDocument));
    } else if (result.noData !== undefined) {
      // It means there is no data to display
      // console.log(result.noData);
      yield put(getHtmlcontentError(result.noData));
    } else {
      // It means there is error
      // console.log(result.error);
      yield put(getHtmlcontentError(result.error));
    }
  } catch (e) {
    console.log(e);
  }
}

function* getMoreHtmlcontentHelper({payload}) {
  try {
    const result = yield call(FirestoreService.getMoreHtmlcontent, payload.lastDocument);
    if (result.data !== undefined) {
      // It means there is  data to display
      // console.log(result.data)
      yield put(getHtmlcontentSuccess(result.data, result.lastDocument));
    } else if (result.noData !== undefined) {
      // It means there is no data to display
      // console.log(result.noData);
      yield put(getHtmlcontentError(result.noData));
    } else {
      // It means there is error
      // console.log(result.error);
      yield put(getHtmlcontentError(result.error));
    }
  } catch (e) {
    console.log(e);
  }
}

export function* getHtmlcontentSaga() {
  yield takeEvery(GET_HTMLCONTENT, getHtmlcontentHelper);
}

export function* getMoreHtmlcontentSaga() {
  yield takeLatest(GET_MORE_HTMLCONTENT, getMoreHtmlcontentHelper);
}

export default function* rootSaga() {
  yield all([fork(getHtmlcontentSaga), fork(getMoreHtmlcontentSaga)]);
}
